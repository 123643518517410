<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="popup-header">
      <div class="popup-title">
        {{ type == 'list' ? '选择服务地址' : '填写服务地址' }}
      </div>
      <div class="close-btn" @click="onClose">关闭</div>
    </div>
    <div class="addr-popup-body" v-show="type === 'list'">
      <div class="addr-item" 
        :class="{'active': addrChecked.id == item.id}"
        v-for="(item, index) in list" :key="index" @click="changeAddr(item)">
        <div class="addr-check-item flex-center">
          <i class="check-icon"></i>
          <img src="~@/assets/images/icon/icon_check.svg" alt="">
        </div>
        <div class="addr-info">
          <div class="title">
            <span class="username van-ellipsis">{{ item.username }}</span>
            <span class="mobile">{{ item.mobile }}</span>
          </div>
          <div class="address">{{ item.province + item.city + item.area + item.address }}</div>
        </div>
        <div class="addr-edit-btn" @click.stop="toEdit(item)">
          <img src="~@/assets/images/icon/icon_edit.svg" alt="">
        </div>
      </div>
    </div>
    <div class="addr-popup-form" v-show="type === 'edit' || type === 'add' ">
      <div class="addr-form-card">
        <div class="form-item van-hairline--bottom">
          <div class="form-label">联系人</div>
          <input type="text" v-model="username" placeholder="请输入联系人" maxlength="10">
        </div>
        <div class="form-item van-hairline--bottom">
          <div class="form-label">联系电话</div>
          <input type="text" v-model="mobile" placeholder="请输入联系电话" maxlength="11">
        </div>
        <div class="form-item van-hairline--bottom">
          <div class="form-label">所在地区</div>
          <div class="form-inner addr" :class="{'placeholder': !area }" @click="showAddrPopup">
            {{ area ? province + ' ' + city + ' ' + area + ' ' + street : '请选择所在地址' }}
          </div>
          <div class="lt-addr-icon"  @click="getLocation">
            <img src="~@/assets/images/icon/icon_location.svg" alt="">
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">详细地址</div>
          <textarea v-model="address" :rows="row" placeholder="请输入街道及门牌号等信息" maxlength="200" @input="autoTextarea"></textarea>
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <van-button class="popup-btn" v-if="type === 'list'" type="info" @click="toNewAddr">添加服务地址</van-button>
      <van-button class="popup-btn" v-if="type === 'add'" type="info" @click="createAddr" :loading="loading">保存</van-button>
      <van-button class="popup-btn" v-if="type === 'edit'" type="info" @click="updateAddr" :loading="loading">保存</van-button>
    </div>

    <van-popup position="bottom" v-model="addrVisible">
      <van-area title="选择所在地区" 
        :area-list="areaList" 
        @cancel="addrVisible=false"
        @confirm="getAddr"
        z-index="2000"
        :value="areaCode"/>
    </van-popup>

  </van-popup>
</template>

<script>
  import { Popup, Button, Area, Toast, Dialog } from 'vant'
  import { areaList } from '@vant/area-data';
  import { createAddress, updateAddress } from '@/api/common'
  import { isPhoneNumber } from '@/utils/common'

  export default {
    components: {
      [Popup.name]: Popup,
      [Button.name]: Button,
      [Area.name]: Area,
    },
    props: {
      visible: {
        type: Boolean
      },
      list: {
        type: Array
      }
    },
    watch: {
      list(val) {
        if (val.length > 0) {
          this.type = 'list'
        } else {
          this.type = 'add'
        }
      }
    },
    data() {
      return {
        type: 'list',
        addrChecked: -1,
        editId: -1,
        province: '',
        city: '',
        area: '',
        username: '',
        mobile: '',
        address: '',
        row: 2,
        areaList,
        areaCode: '',
        street: '',
        loading: false,
        addrVisible: false
      }
    },
    methods: {
      onClose() {
        this.$emit('close', true)
        if (this.list.length > 0) {
          this.type = 'list'
        } else {
          this.type = 'add'
        }
      },
      changeAddr(item) {
        this.addrChecked = item
        this.$emit('submit', item)
      },
      toEdit(item) {
        this.editId = item.id
        this.province = item.province
        this.city = item.city
        this.area = item.area
        this.username = item.username
        this.mobile = item.mobile
        this.address = item.address
        this.street = item.street || ''
        this.areaCode = item.area_code
        this.type = 'edit'
      },
      showAddrPopup() {
        this.addrVisible = true
      },
      getRow(content) {
        let row = 0
        content.split("\n").forEach(item => {
          if (item.length === 0) {
            row += 1
          } else {
            row += Math.ceil(item.replace(/[\u0391-\uFFE5]/g,"aa").length / 32)
          }
        })
        return row
      },
      autoTextarea() {
        const rows = this.getRow(this.address)
        this.row = rows > 2 ? rows : 2
      },
      getAddr(e) {
        this.province = e[0].name
        this.city = e[1].name
        this.area = e[2].name
        this.areaCode = e[2].code
        this.addrVisible = false
      },
      toNewAddr() {
        this.province = ''
        this.city = ''
        this.area = ''
        this.address = ''
        this.street = ''
        this.username = ''
        this.mobile = ''
        this.type = 'add'
      },
      checkAddr() {
        if (this.username.trim() == '') {
          Toast('请输入联系人姓名')
          return false
        }
        if (this.mobile.trim() == '' ) {
          Toast('请输入联系电话')
          return false
        }
        if (!isPhoneNumber(this.mobile)) {
          Toast('手机号码格式不正确')
          return false
        }
        if (!this.areaCode) {
          Toast('请选择所在地区')
          return false
        }
        if (!this.address) {
          Toast('请输入详细地址')
          return false
        }
        return true
      },
      getLocation() {
        const _this = this
        Dialog.confirm({
          title: '获取定位',
          message: '是否获取当前定位',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const AMap = this.$Amap || window.Amap
          const geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
          })
          geolocation.getCurrentPosition(function(status, result){
            if (status=='complete'){
              onComplete(result)
            } else {
              onError(result)
            }
          });

          function onComplete (data) {
            // data是具体的定位信息
            const geocoder = new AMap.Geocoder()

            const lnglat = [data.position.lng, data.position.lat]

            geocoder.getAddress(lnglat, function(status, result) {
              if (status === 'complete' && result.info === 'OK') {
                const info = result.regeocode.addressComponent
                // _this.address = result.regeocode.formattedAddress
                _this.address = info.neighborhood
                _this.province = info.province
                _this.city = info.city
                _this.area = info.district
                _this.street = info.township
                _this.areaCode = info.adcode
              } else {
                Toast('定位失败')
              }
            })
          }

          function onError (data) {
            // 定位出错
            console.log(data)
            Toast('定位失败')
          }
        })
        .catch(() => {
          // on cancel
        });
        
      },
      reinit() {
        this.province = ''
        this.city = ''
        this.area = ''
        this.address = ''
        this.street = ''
        if (this.list.length > 0) {
          this.type = 'list'
        } else {
          this.type = 'add'
        }
        this.editId = -1
        this.areaCode = ''
        this.username = ''
        this.mobile = ''
      },
      createAddr() {
        if (this.checkAddr() && !this.loading) {
          this.loading = true
          const params = {
            username: this.username,
            mobile: this.mobile,
            province: this.province, 
            city: this.city,
            area: this.area, 
            area_code: this.areaCode,
            address: this.address,
            street: this.street
          }
          createAddress(params).then(res => {
            Toast.success('添加成功')
            params.id = res.data.id
            this.addrChecked = params
            this.$emit('change', params)
            this.loading = false
            this.reinit()
          }).catch(() => {
            this.loading = false
          })
        }
      },
      updateAddr() {
        if (this.checkAddr() && !this.loading) {
          this.loading = true
          const params = {
            id: this.editId,
            username: this.username,
            mobile: this.mobile,
            province: this.province, 
            city: this.city,
            area: this.area, 
            area_code: this.areaCode,
            address: this.address,
            street: this.street
          }
          updateAddress(params).then(() => {
            Toast.success('添加成功')
            this.$emit('change', params)
            this.addrChecked = params
            this.loading = false
            this.reinit()
          }).catch(() => {
            this.loading = false
          })
        }
        
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>