<template>
  <div class="smp-card">
    <div class="smp-cell scan-btn hover" @click="onScanButtonClick">
      <div class="smp-cell-main">
        <div class="scan-icon flex-center">
          <img src="~@/assets/images/icon/icon_scan.svg" width="100%" alt="">
        </div>
        <div class="label">扫一扫添加产品</div>
        <div class="action">
          <span>去扫码</span>
          <van-icon name="arrow" size="2.9333vw" color="#9C9DAA"></van-icon>
        </div>
      </div>
    </div>
    <!-- 产品扫码详情 -->
    <ProductDetail
      :info="scanProduct"
      :visible="visible"
       @close="visible=false"
       @submit="addScanProduct"
      />
  </div>
</template>

<script>
  import { Icon, Toast } from 'vant'
  import { useCodeGetPro } from '@/api/common'
  import wx from 'weixin-js-sdk'
  import ProductDetail from './proDetail'

  export default {
    components: {
      [Icon.name]: Icon,
      ProductDetail
    },
    data() {
      return {
        result: '',
        visible: false,
        scanProduct: {}
      }
    },
    methods: {
      onScanButtonClick() {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: res => {
            this.getProductDetail(res.resultStr)
            // alert(JSON.stringify(res))
            // var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            this.result = res.resultStr
            this.$emit('scan', res.resultStr)
          },
          error: () => {
            Toast('没有识别到产品码')
          }
        })
      },
      getProductDetail(code) {
        let _code = code
        if (code.indexOf(',') >= 0) {
          _code = code.split(',')[1]
        }
        const toast = Toast.loading({
          message: '',
          forbidClick: true,
        })
        useCodeGetPro({code: _code}).then(res => {
          toast.clear()
          this.scanProduct = res.data
          this.visible = true
        })
      },
      addScanProduct() {
        this.visible = false
        this.$emit('change', this.scanProduct)
      }
    }
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>