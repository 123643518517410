import request from '@/utils/request'

// 获取分类列表
export function getBusinessCategoryList(data) {
  return request({
    url: '/common/get_business_cate',
    method: 'post',
    data
  })
}

// 获取分类列表
export function getCategoryList(data) {
  return request({
    url: '/common/get_category_list',
    method: 'post',
    data
  })
}

// 添加产品
export function addMyProduct(data) {
  return request({
    url: '/member/add_my_buy_product',
    method: 'post',
    data
  })
}

// 换新
export function createRenewOrder(data) {
  return request({
    url: '/member/add_hg',
    method: 'post',
    data
  })
}

// 厨改订单
export function createKitOrder(data) {
  return request({
    url: '/member/add_kit',
    method: 'post',
    data
  })
}

// 送货订单
export function createDeliveryOrder(data) {
  return request({
    url: '/member/add_delivery',
    method: 'post',
    data
  })
}

// KDS订单
export function createKDSOrder(data) {
  return request({
    url: '/member/add_kds',
    method: 'post',
    data
  })
}

// 前置订单
export function createBeforeOrder(data) {
  return request({
    url: '/member/add_before',
    method: 'post',
    data
  })
}

// 安装订单
export function createInsOrder(data) {
  return request({
    url: '/member/add_ins',
    method: 'post',
    data
  })
}

// 维修订单
export function createRepairOrder(data) {
  return request({
    url: '/member/add_repair',
    method: 'post',
    data
  })
}

// 保养订单
export function createKeepOrder(data) {
  return request({
    url: '/member/add_keep',
    method: 'post',
    data
  })
}

// 前置订单
export function createWashOrder(data) {
  return request({
    url: '/member/add_wash',
    method: 'post',
    data
  })
}

// 改气源
export function createChangeOrder(data) {
  return request({
    url: '/member/add_change',
    method: 'post',
    data
  })
}

// 改气源收费文本
export function getChangeContent(data) {
  return request({
    url: '/common/get_content',
    method: 'post',
    data
  })
}

// 获取故障类型
export function getFaultList(data) {
  return request({
    url: '/common/get_gz_list',
    method: 'post',
    data
  })
}

// 获取故障详情
export function getFaultInfo(data) {
  return request({
    url: '/common/get_gz_info',
    method: 'post',
    data
  })
}

// 获取故障评价状态
export function getFaultStatus(data) {
  return request({
    url: '/member/get_gz_praise_status',
    method: 'post',
    data
  })
}

// 故障评价
export function faultRate(data) {
  return request({
    url: '/member/add_gz_praise',
    method: 'post',
    data
  })
}

// 清洗服务地区是否免费
export function areaIsFree(data) {
  return request({
    url: '/common/is_free',
    method: 'post',
    data
  })
}

