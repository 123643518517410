<template>
  <van-popup v-model="visible" class="pro-detail-popup" @close="onClose">
    <div class="prod-popup-bg">
      <!-- <div class="prod-photo">
        <img :src="info.photo" class="contain-img" alt="">
      </div> -->
      <!-- <div class="prod-name">
        <span>【{{ info.category }}】</span>
        <span>{{ info.title }}</span>
      </div> -->
      <div class="prod-form">
        <div class="prod-cell flex-between">
          <div class="label">产品品类</div>
          <div class="label">{{ info.category }}</div>
        </div>
        <div class="prod-cell flex-between">
          <div class="label">产品型号</div>
          <div class="label">{{ info.title }}</div>
        </div>
        <div class="prod-cell flex-between">
          <div class="label">扫描时间</div>
          <div class="label">{{ info.scan_time }}</div>
        </div>
      </div>
      <van-button round type="info" class="prod-btn" @click="onSubmit">添加服务产品</van-button>
    </div>
    <div class="close-btn" @click="onClose">
      <img src="~@/assets/images/icon/icon_popup_close.svg" width="100%" alt="">
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Button } from 'vant'
  export default {
    components: {
      [Popup.name]: Popup,
      [Button.name]: Button,
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      info: {
        type: Object
      }
    },
    data() {
      return {
      }
    },
    methods: {
      onClose() {
        this.$emit('close', true)
      },
      onSubmit() {
        this.$emit('submit', true)
      },
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>