<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="popup-header">
      <div class="popup-title">
        请选择添加产品
      </div>
      <div class="close-btn" @click="onClose">关闭</div>
    </div>
    <div class="pro-filter">
      <div class="pro-filter-card"
        @click="checkIndex = index"
        v-for="(item, index) in list" :key="index">
        <div class="photo">
          <img :src="item.photo" class="contain-img" alt="">
        </div>
        <div class="info">
          <div class="title van-ellipsis">{{ item.category }}</div>
          <div class="desc van-ellipsis">{{ item.title || '型号暂未填写'}}</div>
        </div>
        <div class="check-item" :class="{'active': checkIndex == index}">
          <img src="~@/assets/images/icon/icon_check.svg" alt="">
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <van-button v-if="checkIndex !== -1" class="popup-btn" type="info" @click="onSubmit">
        确定
      </van-button>
      <van-button v-else class="popup-btn" type="info" @click="onCreate">
        选择添加产品
      </van-button>
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Button } from 'vant'

  export default {
    components:{
      [Popup.name]: Popup,
      [Button.name]: Button,
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      list: {
        type: Array
      }
    },
    data() {
      return {
        checkIndex: -1
      }
    },
    methods: {
      reinit() {
        this.checkIndex = -1
      },
      onClose() {
        this.$emit('close', true)
        this.reinit()
      },
      onSubmit() {
        this.$emit('submit', this.list[this.checkIndex])
        this.reinit()
      },
      onCreate() {
        this.$emit('create', true)
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>