<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="popup-header">
      <div class="popup-title">
        产品品类
      </div>
    </div>
    <div class="ap-popup-body">
      <div class="ap-list">
        <div class="ap-item" v-for="(item, index) in cateList" :key="index" @click="checkIndex = index">
          <div class="check-item" :class="{'active': checkIndex === index}">
            <img src="~@/assets/images/icon/icon_check.svg" alt="">
          </div>
          <div class="ap-label">{{ item.title }}</div>
          <div class="ap-input-box">
            <input type="text" v-model="item.value" placeholder="可输入产品型号" maxlength="20">
          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <van-button class="popup-btn mini" type="default" @click="onBack">
        返回
      </van-button>
      <van-button class="popup-btn small" type="info" :loading="loading" @click="onSubmit">
        确认
      </van-button>
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Button, Toast } from 'vant'
  import { addMyProduct } from '@/api/service'

  export default {
    components:{
      [Popup.name]: Popup,
      [Button.name]: Button,
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      list: {
        type: Array
      }
    },
    data() {
      return {
        checkIndex: -1, 
        cateList: [],
        loading: false
      }
    },
    mounted() {
      this.cateList = this.list.map(el => { 
        el.value = ''
        return el
      })
    },
    methods: {
      onClose() {
        this.reinit()
        this.$emit('close', true)
      },
      onBack() {
        this.reinit()
        this.$emit('back', true)
      },
      reinit() {
        this.checkIndex = -1
        this.loading = false
        this.cateList.forEach(el => {
          el.value = ''
        })
      },
      onSubmit() {
        if (this.checkIndex == -1) {
          Toast('请选择一款产品')
          return
        }
        this.loading = true 
        const item = this.cateList[this.checkIndex]
        const params = {
          category: item.title,
          title: item.value
        }
        addMyProduct(params).then(res => {
          Toast.success('添加成功')
          const result = {
            ...params,
            id: res.data.id,
            photo: res.data.photo || item.photo
          }
          
          this.$emit('submit', result)
          this.reinit()
        }).catch(() => {
          this.loading = false
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>